var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.mode === 'edit')?_c('vue-drag-resize',{key:_vm.loginPageInfo.loginBox.timestamp,staticClass:"login-box-container",style:({ opacity: _vm.loginPageInfo.loginBox.opacity }),attrs:{"w":_vm.loginPageInfo.loginBox.width,"h":_vm.loginPageInfo.loginBox.height,"x":_vm.loginPageInfo.loginBox.left,"y":_vm.loginPageInfo.loginBox.top,"z":_vm.loginPageInfo.loginBox.zIndex,"minw":150,"minh":150,"aspectRatio":true,"preventActiveBehavior":true,"isActive":_vm.activatedItem === 'loginBox'},on:{"clicked":_vm.onClicked,"resizing":_vm.onResizing,"dragging":_vm.onDragging}},[_c(_vm.loginBoxComp,{tag:"components",staticStyle:{"overflow":"hidden"},attrs:{"templateInfo":_vm.templateInfo,"loginPageInfo":_vm.loginPageInfo,"loginForm":_vm.loginForm,"loginRules":_vm.loginRules,"loginButtonLoading":_vm.loginButtonLoading},on:{"loginMethodSwitch":_vm.handleLoginMethodSwitch}})],1):_c('div',{class:_vm.classes,style:({
        position: 'absolute',
        width: _vm.loginPageInfo.loginBox.width + 'px',
        height: _vm.loginPageInfo.loginBox.height + 'px',
        top: _vm.loginPageInfo.loginBox.top + 'px',
        left: _vm.loginPageInfo.loginBox.left + 'px',
        opacity: _vm.loginPageInfo.loginBox.opacity,
        zIndex: _vm.loginPageInfo.loginBox.zIndex,
        transform: `scale(${_vm.wheelZoomRatio}%)`
    })},[_c(_vm.loginBoxComp,{tag:"components",staticStyle:{"overflow":"hidden"},attrs:{"templateInfo":_vm.templateInfo,"loginPageInfo":_vm.loginPageInfo,"loginForm":_vm.loginForm,"loginRules":_vm.loginRules,"loginButtonLoading":_vm.loginButtonLoading},on:{"loginMethodSwitch":_vm.handleLoginMethodSwitch,"loginRulesErr":_vm.loginRulesErr}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }