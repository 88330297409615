<template>
    <vue-drag-resize
        v-if="mode === 'edit'"
        :key="loginPageInfo.loginBox.timestamp"
        class="login-box-container"
        :style="{ opacity: loginPageInfo.loginBox.opacity }"
        :w="loginPageInfo.loginBox.width"
        :h="loginPageInfo.loginBox.height"
        :x="loginPageInfo.loginBox.left"
        :y="loginPageInfo.loginBox.top"
        :z="loginPageInfo.loginBox.zIndex"
        :minw="150"
        :minh="150"
        :aspectRatio="true"
        :preventActiveBehavior="true"
        :isActive="activatedItem === 'loginBox'"
        @clicked="onClicked"
        @resizing="onResizing"
        @dragging="onDragging">
        <components
            style="overflow: hidden;"
            :is="loginBoxComp"
            :templateInfo="templateInfo"
            :loginPageInfo="loginPageInfo"
            :loginForm="loginForm"
            :loginRules="loginRules"
            :loginButtonLoading="loginButtonLoading"
            @loginMethodSwitch="handleLoginMethodSwitch"
        ></components>
    </vue-drag-resize>
    <div
        v-else
        :class="classes"
        :style="{
            position: 'absolute',
            width: loginPageInfo.loginBox.width + 'px',
            height: loginPageInfo.loginBox.height + 'px',
            top: loginPageInfo.loginBox.top + 'px',
            left: loginPageInfo.loginBox.left + 'px',
            opacity: loginPageInfo.loginBox.opacity,
            zIndex: loginPageInfo.loginBox.zIndex,
            transform: `scale(${wheelZoomRatio}%)`
        }">
        <components
            style="overflow: hidden;"
            :is="loginBoxComp"
            :templateInfo="templateInfo"
            :loginPageInfo="loginPageInfo"
            :loginForm="loginForm"
            :loginRules="loginRules"
            :loginButtonLoading="loginButtonLoading"
            @loginMethodSwitch="handleLoginMethodSwitch"
            @loginRulesErr="loginRulesErr"
        ></components>
    </div>
</template>

<script>
import VueDragResize from "vue-drag-resize";
import LoginBox1 from "./LoginBox1/index.vue";
import LoginBox2 from "./LoginBox2/index.vue";
import LoginBox3 from "./LoginBox3/index.vue";

export default {
    name: 'LoginBoxContainer',
    components: {
        VueDragResize,
        LoginBox1,
        LoginBox2,
        LoginBox3
    },
    props: {
        mode: {
            type: String
        },
        templateInfo: {
            type: Object
        },
        loginPageInfo: {
            type: Object
        },
        wheelZoomRatio: {
            type: Number
        },
        activatedItem: {
            type: String
        },
        loginForm: {
            type: Object
        },
        loginRules: {
            type: Object
        },
        loginButtonLoading: {
            type: Boolean
        }
    },
    data() {
        return {
            rulesErrlogin: false,
            timer: null,
        }
    },
    filters: {},
    computed: {
        classes() {
            let cls = 'login-box-container';
            return [cls, { 'shake-animation': this.rulesErrlogin }]
        },
        loginBoxComp() {
            return `LoginBox${this.loginPageInfo.theme}`
        }
    },
    watch: {
        'loginPageInfo': {
            handler () {
                console.log(this.loginPageInfo.theme, '变化')
            }
        }
    },
    created() {
        this.loginForm.curLoginMethod = this.templateInfo.defaultLoginMethod
    },
    beforeDestroy() {
        this.timer = null;
    },
    methods: {
        onClicked() {
            this.$eventDispatch('loginBoxClick')
        },
        onResizing(e) {
            this.$eventDispatch('loginBoxResizing', e);
        },
        onDragging(e) {
            this.$eventDispatch('loginBoxDragging', e);
        },
        handleLoginMethodSwitch(loginMethod) {
            this.loginForm.curLoginMethod = loginMethod
            // console.log("[handleLoginMethodSwitch]this.loginForm.curLoginMethod:", this.loginForm.curLoginMethod)
        },
        loginRulesErr() {
            const _this = this;
            this.rulesErrlogin = true;
            this.timer = setTimeout(() => {
                _this.rulesErrlogin = false;
            }, 300)
        }
    },
    
}
</script>

<style lang="scss" scoped>
    @keyframes shake {
        0% { transform: translateX(0); }
        25% { transform: translateX(-10px); }
        50% { transform: translateX(10px); }
        75% { transform: translateX(-10px); }
        100% { transform: translateX(10px); }
    }
        
    .shake-animation {
        animation: shake .3s;
        animation-iteration-count: infinite;
    }
</style>
